import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { mobileSize } from '../../../util/variables';
import { MobileDiv, Overline } from '../../ui';
import Select from 'react-select';

const selectStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    backgroundColor: '#EEEEEE',
    color: 'black',
    border: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#EEEEEE',
    color: 'black',
    borderRadius: 0,
    border: 'none',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
};

interface Props {
  data: Array<{
    node: {
      id: string;
      title: string;
      updatedAt: string;
      orderNumber: number;
    };
  }>;
  selected?: string;
  // setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export const Categories: React.FC<Props> = ({ data, selected }) => {
  // const [selected, setSelected] = useState('Everything');

  const getCategoryLink = (title: string) =>
    title === 'Everything' ? '/blog/' : `/blog/${title.replace(/\s+/g, '-').toLowerCase()}`;
  return (
    <div>
      <Overline>SHOW ME</Overline>
      <CategoriesContainer>
        {/* <CategoryText size="Large" selected={selected === undefined} to="/blog/">
          Everything
        </CategoryText> */}
        {!!data &&
          data
            .slice()
            .sort((a, b) => a.node.orderNumber - b.node.orderNumber)
            .map(item => (
              <CategoryText
                key={item.node.id}
                size="Large"
                selected={selected === item.node.title}
                to={getCategoryLink(item?.node?.title)}
              >
                {item.node.title}
              </CategoryText>
            ))}
      </CategoriesContainer>
      <MobileDiv>
        {/* <select /> */}
        {!!data && (
          <Select
            styles={selectStyles}
            value={{ label: selected, value: selected }}
            options={data
              .slice()
              .sort((a, b) => a.node.orderNumber - b.node.orderNumber)
              .map(item => ({ label: item.node.title, value: item.node.title }))}
            onChange={async e => {
              // setSelected(e.value);
              navigate(getCategoryLink(e.value));
            }}
          />
        )}
      </MobileDiv>
    </div>
  );
};

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 341px;
  width: 272px;
  overflow-y: auto;
  @media ${mobileSize} {
    display: none;
  }
`;

const CategoryText = styled(Link as any)<{ selected: boolean }>`
  text-decoration: none;
  font-size: 20px;
  margin: 0;
  font-weight: 700;
  color: ${props => (props.selected ? theme.PRIMARY_COLOR : theme.PITCH_BLACK)};
  cursor: pointer;
  transition: color 0.15s ease-in-out;
`;
