import React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../../util/variables';
import { BlogH5, FlexDiv } from '../../ui';
import { FeaturedArticleCard, LargeOverlayArticleCard } from './cards';

interface Props {
  smallArticles: any[];
  largeArticle: any;
}

const testData = [0, 1, 2];

export const FeaturedSection: React.FC<Props> = ({ smallArticles, largeArticle }) => {
  return (
    <FlexDiv direction="column">
      <BlogH5>Featured</BlogH5>
      <ArticlesGrid>
        <FlexDiv direction="column" gap={24}>
          {smallArticles.map(article => (
            <FeaturedArticleCard
              key={`small-featured-${article.id}`}
              id={article.contentful_id}
              category={article.mainCategory.title}
              title={article.title}
              slug={article.slug}
              imgUrl={`https:${article?.image?.file?.url}`}
            />
          ))}
        </FlexDiv>
        {largeArticle.id && (
          <LargeOverlayArticleCard
            category={largeArticle.mainCategory.title}
            id={largeArticle.contentful_id}
            title={largeArticle.title}
            imgUrl={`https:${largeArticle?.image?.file?.url}`}
            slug={largeArticle.slug}
          />
        )}
      </ArticlesGrid>
    </FlexDiv>
  );
};

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;
