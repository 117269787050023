import React, { useEffect, useMemo, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import IndexLayout from '../layouts';
import { Helmet } from 'react-helmet';
import favicon from '../content/img/icon/favicon.ico';
import { BlogH1, FlexDiv, NavBar, Separator, Wrapper } from '../components/ui';
import styled, { keyframes } from 'styled-components';
import { BackToTop } from '../components/ui/BackToTopButton';
import { Categories } from '../components/PageSegments/blog/Categories';
import { FeaturedSection, Suggestions } from '../components/PageSegments/blog';
import { MainFeaturedArticleCard } from '../components/PageSegments/blog/cards';
import { ArticlesSection } from '../components/PageSegments/blog/ArticlesSection';
import { BottomNavSegment } from '../components/PageSegments';
import { mobileSize } from '../util/variables';
import theme from '../util/theme';
import welcomeLogo from '../content/img/icon/welcome-icon.svg';

export default ({ data, pageContext }) => {
  const { allContentfulArticles, allContentfulCategories } = data;

  console.log(dataBlog);

  const [category, setCategory] = useState('Everything');

  // TODO when more blog content is added in, this redirect will be removed
  useEffect(() => {
    console.log('testing blog');
    navigate('/content');
  }, []);

  useEffect(() => {
    if (pageContext?.urlCategory !== category && !!allContentfulCategories) {
      const index = allContentfulCategories.edges
        .slice()
        .map((item: any) => item.node.title)
        .indexOf(pageContext?.urlCategory);
      // alert(JSON.stringify(index));
      if (index >= 0) {
        setCategory(allContentfulCategories.edges[index].node.title);
      }
    }
  }, [pageContext, allContentfulCategories, category]);

  // Takes in category in the format '/blog/category' and returns member
  // const urlCategory = location.pathname.split('/')[2];

  const returnSelectedCategoryData = useMemo(() => {
    console.log(allContentfulCategories);
    if (allContentfulCategories?.edges.length) {
      const selectedData = allContentfulCategories?.edges
        ?.slice()
        .filter((item: any) => item.node.title === category);
      // alert(JSON.stringify(pageContext?.urlCategory));
      return selectedData.length ? selectedData[0].node : {};
    }

    return {};
  }, [category, allContentfulCategories]);

  // TODO when more blog content is added in, this redirect will be removed
  return (
    <IndexLayout>
      <Helmet>
        <title>Blog - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/blog/" />
        <meta charSet="UTF-8" />
      </Helmet>
      <NavBar invert anchorToHere initiallyBlack={false} />
    </IndexLayout>
  );

  return (
    <IndexLayout>
      <Helmet>
        <title>Blog - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/blog/" />
        <meta charSet="UTF-8" />
      </Helmet>
      <NavBar invert anchorToHere initiallyBlack={false} />
      <Main>
        <ContentContainer>
          <SpaceBetweenDiv>
            <Title>Insights on Automation for the Real Economy</Title>
            <RotatingImg src={welcomeLogo} alt="Welcome to the Base10 Blog" />
          </SpaceBetweenDiv>
          <DesktopDiv>
            <Separator />
          </DesktopDiv>
          <FilterArticleContainer>
            <Categories
              selected={category}
              // setSelected={setCategory}
              data={allContentfulCategories.edges}
            />
            <MobileDiv>
              <Separator />
            </MobileDiv>
            {returnSelectedCategoryData?.youMightLike?.length && (
              <Suggestions data={returnSelectedCategoryData.youMightLike} />
            )}
          </FilterArticleContainer>
          {/* {urlCategory} */}
          {/* <MobileDiv> */}
          {/* <MainFeaturedArticleCard
            size="hero"
            id={returnSelectedCategoryData?.mainArticle?.contentful_id}
            category={returnSelectedCategoryData?.mainArticle?.mainCategory?.title}
            title={returnSelectedCategoryData?.mainArticle?.title}
            description={returnSelectedCategoryData?.mainArticle?.summary}
            image={`https:${returnSelectedCategoryData?.mainArticle?.image?.file?.url}`}
          /> */}
          {/* </MobileDiv> */}
          <Separator />
          <ArticlesSection data={allContentfulArticles?.nodes} />
          <Separator />
          <MainFeaturedArticleCard
            id={returnSelectedCategoryData?.wideArticle?.contentful_id}
            size="large"
            category={returnSelectedCategoryData?.wideArticle?.mainCategory?.title}
            title={returnSelectedCategoryData?.wideArticle?.title}
            description={returnSelectedCategoryData?.wideArticle?.summary}
            imgUrl={`https:${returnSelectedCategoryData?.wideArticle?.image?.file?.url}`}
            slug={returnSelectedCategoryData?.wideArticle?.slug}
          />
          <Separator />
          {returnSelectedCategoryData?.featuredArticlesSmall && (
            <FeaturedSection
              smallArticles={returnSelectedCategoryData?.featuredArticlesSmall}
              largeArticle={returnSelectedCategoryData?.featuredArticleLarge}
            />
          )}
        </ContentContainer>
        <BackToTop />
      </Main>
      <BottomNavSegment />
    </IndexLayout>
  );
};

const rotate = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const RotatingImg = styled.img`
  animation: 10s ${rotate} linear infinite;
  @media ${mobileSize} {
    display: none;
  }
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;
  background-color: ${theme.BACKGROUND_COLOR};
  /* max-width:  */
  /* @media ${mobileSize} {
    width: 100vw;
  } */
`;

const ContentContainer = styled.div`
  max-width: min(1164px, 100vw);
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  @media ${mobileSize} {
    box-sizing: border-box;
    width: 95vw;
  }
`;

const Title = styled.h1`
  font-size: 65px;
  margin-top: 40px;
  @media ${mobileSize} {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

const FilterArticleContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  @media ${mobileSize} {
    flex-direction: column;
  }
`;

const DesktopDiv = styled.div`
  display: block;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

export const dataBlog = graphql`
  query BlogData($categoryId: String) {
    allContentfulCategories {
      edges {
        node {
          id
          contentful_id
          title
          orderNumber
          youMightLike {
            contentful_id
            id
            slug
            title
            summary
            date
            updatedAt
            image {
              id
              file {
                url
              }
            }
            previewImage {
              id
              file {
                url
              }
            }
          }
          mainArticle {
            contentful_id
            id
            slug
            title
            summary
            author
            date
            image {
              id
              file {
                url
              }
            }
            previewImage {
              id
              file {
                url
              }
            }
            mainCategory {
              id
              title
            }
            updatedAt
          }
          wideArticle {
            contentful_id
            id
            slug
            title
            summary
            author
            date
            image {
              id
              file {
                url
              }
            }
            previewImage {
              id
              file {
                url
              }
            }
            mainCategory {
              id
              title
            }
            updatedAt
          }
          featuredArticlesSmall {
            contentful_id
            id
            slug
            title
            summary
            author
            date
            updatedAt
            mainCategory {
              id
              title
            }
            image {
              id
              file {
                url
              }
            }
            previewImage {
              id
              file {
                url
              }
            }
          }
          featuredArticleLarge {
            contentful_id
            id
            slug
            title
            summary
            author
            date
            updatedAt
            mainCategory {
              id
              title
            }
            image {
              id
              file {
                url
              }
            }
            previewImage {
              id
              file {
                url
              }
            }
          }
          updatedAt
        }
      }
    }
    allContentfulArticles(filter: { mainCategory: { id: { eq: $categoryId } } }) {
      totalCount
      nodes {
        id
        contentful_id
        slug
        title
        summary
        mainCategory {
          id
          title
        }
        author
        date
        image {
          id
          file {
            url
          }
        }
        updatedAt
        body {
          body
        }
      }
    }
  }
`;
