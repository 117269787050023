import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import IndexLayout from '../../../layouts';
import {
  BlogH2,
  BlogH5,
  BlogText,
  FlexDiv,
  IconContainer,
  NavBar,
  Overline,
  Separator,
  Wrapper,
} from '../../ui';
import favicon from '../../../content/img/icon/favicon.ico';
import theme from '../../../util/theme';
import { FaTwitter, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import { FeaturedArticleCard } from './cards/FeaturedArticleCard';
import { ArticlesSection } from './ArticlesSection';
import { graphql, useQuery } from 'gatsby';
import { AppText } from '../../ui/AppText';

interface ArticlePageProps {
  // TODO: update to article page model
  // data: any;
  // postId: string;
}

export const ArticlePage: React.FC<ArticlePageProps> = ({ data }) => {
  const currentUrl = 'google.com';

  return (
    <IndexLayout>
      <Helmet>
        <title>Blog Article - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/" />
        <meta charSet="UTF-8" />
      </Helmet>
      <Main>
        <NavBar invert initiallyBlack={false} />
        <HeroGrid>
          <Placeholder />
          <FlexDiv direction="column" justify="center" style={{ padding: '92px' }}>
            <Overline />
            <BlogH2>
              Quarterly sales are at an all-time low my grasp on reality right now is tenuous going
              forward, or code and put your feelers out
            </BlogH2>
            <BlogText size="Small">
              Blue sky thinking please submit the sop and uat files by next monday. Pivot it just
              needs more cowbell we need to build it so that it scales build on a culture of
              contribution and inclusion.
            </BlogText>
            <BlogText noMargin size="Small" color={theme.PITCH_BLACK}>
              By <b>Natalia Garrett</b>
            </BlogText>
            <BlogText size="Small" color={theme.GREY_400}>
              June 22, 2022
            </BlogText>
            <FlexDiv gap={16}>
              <a
                href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20article%20from%20the%20Base10%20blog!&url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#00A4E9">
                  <FaTwitter color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#0E76A8">
                  <FaLinkedinIn color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
              <a
                href={`mailto:?subject=Check%20out%20this%20article%20from%20the%20Base10%20blog!&body=Check%20out%20this%20article%20from%20the%20Base10%20blog:%20${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#173053">
                  <FaEnvelope color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
            </FlexDiv>
          </FlexDiv>
        </HeroGrid>
        <Wrapper>
          <ContentGrid>
            <StickyWrapper>
              <Featured>
                <BlogH5>Featured</BlogH5>
                {/* <FeaturedArticleCard
                  category="CATEGORY"
                  title="Offline this discussion cloud native container based can you send me an invite quick win"
                />
                <FeaturedArticleCard
                  category="CATEGORY"
                  title="Anti-pattern deep dive data-point onward and upward, productize the deliverables"
                /> */}
              </Featured>
            </StickyWrapper>
            {/* <BlogText size="Small">
              Sorry i didn't get your email synergize productive mindfulness forcing function yet
              best practices. Data-point reinvent the wheel. I know you're busy let's take this
              conversation offline execute design thinking, iâ€™ve been doing some research this
              morning and we need to better back-end of third quarter. Come up with something
              buzzworthy i also believe it's important for every member to be involved and invested
              in our company and this is one way to do so so after I ran into Helen at a restaurant,
              I realized she was just office pretty, or turn the ship. This is a no-brainer tread it
              daily hire the best but circle back. Anti-pattern we need to leverage our synergies,
              but circle back herding cats. We don't need to boil the ocean here proceduralize pixel
              pushing. Good optics gain traction locked and loaded can you slack it to me?. Paddle
              on both sides pass the mayo, appeal to the client, sue the vice president or we need
              to crystallize a plan so at the end of the day, and we just need to put these last
              issues to bed. No scraps hit the floor waste of resources how much bandwidth do you
              have or reinvent the wheel, or build on a culture of contribution and inclusion.
              Knowledge process outsourcing low engagement, create spaces to explore whatâ€™s next
              nor this medium needs to be more dynamic prethink, but not a hill to die on roll back
              strategy. Finance enough to wash your face this is our north star design nor
              re-inventing the wheel. Granularity I have zero cycles for this cross pollination
              across our domains window of opportunity. High touch client let's schedule a standup
              during the sprint to review our kpis yet value prop but quick sync but we need to make
              the new version clean and sexy, so UX. Loop back pipeline synergize productive
              mindfulness we need to think big start small and scale fast to energize our clients
              sorry i didn't get your email when does this sunset? . Imagineer wheelhouse, for let's
              schedule a standup during the sprint to review our kpis granularity, shelfware donuts
              in the break room. PowerPointless we need this overall to be busier and more active
              and no scraps hit the floor. Gain alignment. Business impact cc me on that into the
              weeds we just need to put these last issues to bed execute . Let's prioritize the
              low-hanging fruit currying favour, for performance review nor we've got kpis for that
              golden goose, and make sure to include in your wheelhouse up the flagpole bazooka that
              run it past the boss jump right in and banzai attack will they won't they its all
              greek to me unless they bother until the end of time maybe vis a vis too many cooks
              over the line. Can we take this offline let's pressure test this. Pig in a python we
              need to button up our approach quick win low engagement and code. Cross sabers that
              ipo will be a game-changer yet performance review so we just need to put these last
              issues to bed, yet nail jelly to the hothouse wall nail jelly to the hothouse wall,
              yet land it in region. We need to have a Come to Jesus meeting with Phil about his
              attitude upstream selling but flesh that out, we've bootstrapped the model can you put
              it into a banner that is not alarming, but eye catching and not too giant nor reinvent
              the wheel. Accountable talk. Cannibalize. Let me know if you need me to crack any
              skulls accountable talk meeting assassin, yet on this journey can you send me an
              invite?. Turn the crank innovation is hot right now. On this journey fire up your
              browser, so circle back nor knowledge process outsourcing or window-licker big data.
              Crisp ppt let's pressure test this and pivot nor shoot me an email target rich
              environment. Please submit the sop and uat files by next monday baseline the procedure
              and samepage your department, and on-brand but completeley fresh. Talk to the slides
              organic growth this is our north star design. Exposing new ways to evolve our design
              language sacred cow single wringable neck or out of the loop. I am dead inside.
              Slow-walk our commitment disband the squad but rehydrate as needed, lean into that
              problem . Three-martini lunch we should have a meeting to discuss the details of the
              next meeting for dunder mifflin or usabiltiy. Herding cats quick win lean into that
              problem so hop on the bandwagon sorry i was triple muted we need to future-proof this
              slow-walk our commitment. We need more paper cloud native container based minimize
              backwards overflow for focus on the customer journey. Value-added. Let's prioritize
              the low-hanging fruit. We are running out of runway circle back yet land it in region
              or let me know if you need me to crack any skulls clear blue water yet at the end of
              the day we should leverage existing asserts that ladder up to the message. Cannibalize
              we need to touch base off-line before we fire the new ux experience so problem
              territories. C-suite powerPointless. We should have a meeting to discuss the details
              of the next meeting bottleneck mice beef up, and blue sky thinking, mumbo jumbo or
              marginalised key performance indicators. Cannibalize powerPointless, but due
              diligence, nor groom the backlog, agile. Shelfware design thinking spinning our wheels
              for service as core &innovations as power makes our brand. Peel the onion. What's our
              go to market strategy? you must be muted. Start procrastinating 2 hours get to do work
              while procrastinating open book pretend to read while manager stands and watches
              silently nobody is looking quick do your web search manager caught you and you are
              fured guerrilla marketing marketing, illustration so put it on the parking lot closer
              to the metal. Identify pain points. I'm sorry i replied to your emails after only
              three weeks, but can the site go live tomorrow anyway? back to the drawing-board, nor
              make it more corporate please nor low hanging fruit and where the metal hits the meat
              are we in agreeance. Start procrastinating 2 hours get to do work while
              procrastinating open book pretend to read while manager stands and watches silently
              nobody is looking quick do your web search manager caught you and you are fured sea
              change no scraps hit the floor, for let's put a pin in that i don't want to drain the
              whole swamp, i just want to shoot some alligators we need to have a Come to Jesus
              meeting with Phil about his attitude cannibalize. We need to button up our approach
              mobile friendly, i'm sorry i replied to your emails after only three weeks, but can
              the site go live tomorrow anyway? green technology and climate change so minimize
              backwards overflow, but hop on the bandwagon for level the playing field. Baseline cc
              me on that, or i have a hard stop in an hour and half, big boy pants Q1 blue sky
              incentivize adoption. UX post launch or face time product launch what about scaling
              components to a global audience? price point. High-level high-level so this is a
              no-brainer can we align on lunch orders we need to leverage our synergies, so parallel
              path. Ladder up / ladder back to the strategy conversational content nor player-coach.
              Looks great, can we try it a different way marginalised key performance indicators
              let's pressure test this win-win-win and hammer out. Nail jelly to the hothouse wall
              dogpile that golden goose run it up the flagpole, ping the boss and circle back but
              i'll book a meeting so we can solution this before the sprint is over get in the
              driver's seat, make it more corporate please. Driving the initiative forward pass the
              mayo, appeal to the client, sue the vice president . Focus on the customer journey
              turn the ship, five-year strategic plan. We've bootstrapped the model. Make it a
              priority rock Star/Ninja, keep it lean but weaponize the data synergize productive
              mindfulness pipeline. Data-point incentivize adoption so highlights . Define the
              underlying principles that drive decisions and strategy for your design language
              bottleneck mice I have zero cycles for this wiggle room, nor roll back strategy. We
              need a paradigm shift ensure to follow requirements when developing solutions my grasp
              on reality right now is tenuous but what's the real problem we're trying to solve
              here?. Let's prioritize the low-hanging fruit three-martini lunch. Anti-pattern the
              closest elephant is the most dangerous and it's about managing expectations ramp up
              low hanging fruit. Cross functional teams enable out of the box brainstorming going
              forward, or programmatically, when does this sunset? . Prioritize these line items
              parallel path for through the lens of we need this overall to be busier and more
              active. Low hanging fruit cross-pollination, a loss a day will keep you focus. Gain
              traction we need more paper but strategic high-level 30,000 ft view so define the
              underlying principles that drive decisions and strategy for your design language yet
              let's put a pin in that and note for the previous submit: the devil should be on the
              left shoulder but draw a line in the sand. A loss a day will keep you focus we need to
              aspirationalise our offerings powerpoint Bunny, and your work on this project has been
              really impactful, and strategic high-level 30,000 ft view window-licker pushback.
              Start procrastinating 2 hours get to do work while procrastinating open book pretend
              to read while manager stands and watches silently nobody is looking quick do your web
              search manager caught you and you are fured strategic fit. Going forward that jerk
              from finance really threw me under the bus market-facing, and this medium needs to be
              more dynamic weâ€™re all in this together, even if our businesses function
              differently. Parallel path do i have consent to record this meeting so vec looks
              great, can we try it a different way. I just wanted to give you a heads-up big
              picture. In an ideal world pass the mayo, appeal to the client, sue the vice president
              . Pixel pushing cross pollination across our domains but focus on the customer
              journey. Offline this discussion come up with something buzzworthy, and increase the
              resolution, scale it up we need a larger print proceduralize. Social currency if you
              could do that, that would be great, crank this out gain traction we don't need to boil
              the ocean here. C-suite forcing function yet we need to button up our approach
              translating our vision of having a market leading platfrom who's responsible for the
              ask for this request? time vampire or quick win. 360 degree content marketing pool
              quick win. Those options are already baked in with this model service as core
              &innovations as power makes our brand regroup, social currency so if you're not
              hurting you're not winning. Sea change. The right info at the right time to the right
              people. You gotta smoke test your hypothesis make it look like digital for we need to
              think big start small and scale fast to energize our clients but loop back. If you're
              not hurting you're not winning. What's the status on the deliverables for eow?.
            </BlogText> */}
            <BlogText>{JSON.stringify(data)}</BlogText>
            <BlogText>text here</BlogText>
          </ContentGrid>
          <Separator />
          <ArticlesSection data={[]} />
        </Wrapper>
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  /* max-width:  */
`;

const HeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: calc(100vh - 120px);
`;

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: blue;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 128px;
  padding: 80px 0;
`;

const StickyWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Featured = styled.div`
  display: Flex;
  flex-direction: column;
  grid-gap: 40px;
  position: sticky;
  top: 0;
`;
