import React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../../util/variables';
import { BlogH5, FlexDiv } from '../../ui';
import { OverlayArticleCard } from './cards';

interface SuggestionsProps {
  data: any;
}

const testData = [0, 1, 2, 3, 4];

export const Suggestions: React.FC<SuggestionsProps> = ({ data }) => {
  return (
    <FlexDiv direction="column">
      <BlogH5>You Might Like</BlogH5>
      <ArticlesContainer>
        {data.map((article: any) => (
          <OverlayArticleCard
            key={article.id}
            id={article.contentful_id}
            category={article.mainCategory?.title}
            title={article.title}
            imgUrl={article?.image?.file?.url ? `https:${article?.image?.file?.url}` : undefined}
            slug={article.slug}
          />
        ))}
      </ArticlesContainer>
    </FlexDiv>
  );
};

const ArticlesContainer = styled(FlexDiv as any)`
  gap: 16px;
  /* max-width: min(860px, 60vw); */
  // | space to the right of container |   |space for the articles within wrapper|
  // screen_width - wrapper_width / 2 () + wrapper_width - categories_width
  width: calc((100vw - 1164px) / 2 + (1164px - 272px));
  overflow-x: auto;
  @media ${mobileSize} {
    gap: 24px;
    width: calc(100vw - 30px);
  }
`;
